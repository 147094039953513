import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import { IKeywordRequestPayload, IKeywordResponsePayload } from 'shared/interfaces/IKeyword';
import { transformPayload } from 'shared/utils/transformPayload';

const { GET } = useAxios();

export const useKeywordDao = () => {
  const getKeywords = async (payload?: IKeywordRequestPayload) => {
    const transformedPayload = transformPayload<IKeywordRequestPayload>(payload);

    const response = await GET<IKeywordResponsePayload>({
      url: ENDPOINTS.KEYWORDS,
      params: {
        token: transformedPayload,
      },
    });

    return response.data;
  };

  const getKeywordShow = async (payload: any) => {
    const transformedPayload = transformPayload<any>(payload);
    const response = await GET<any>({
      url: ENDPOINTS.KEYWORDS + '/show',
      params: {
        token: transformedPayload,
      },
    });

    return response.data;
  };

  return {
    getKeywords,
    getKeywordShow,
  };
};
