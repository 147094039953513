import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useBucketService } from 'shared/services/bucketService';
import { useCategoryService } from 'shared/services/categoryService';
import Create from './Create';
import { Alert } from 'shared/theme/elements';
import { useQueryClient } from 'react-query';
import { ROUTES } from 'shared/constants/ROUTES';
import { ls } from 'shared/utils/ls';
import { SpinnerWrapper } from 'components/SpinnerWrapper';
import useLoading from 'shared/hooks/useLoading';

export interface SavingFocusKeywords {
  bucket_name: string;
  proceed: boolean;
  focusKeywords: any[];
  save_alert: string;
}

function Container() {
  const queryClient = useQueryClient();
  const { push } = useHistory();
  const user = localStorage?.getItem('user_admin') ? JSON.parse(localStorage.getItem('user_admin') || '') : null;

  const { state } = useLocation();
  const locationState = state as any;

  // CUSTOM LOADING HOOK
  const { loading, setLoading } = useLoading();

  const [proceed, setProceed] = useState<boolean>(false);
  const [selected_boolean_keywords, set_boolean_keywords] = useState<string>('');
  const [selected_category, set_selected_category] = useState<string>(
    locationState?.category_name ? locationState?.category_name : '',
  );
  const [buckets_save_credentials, setbucketsavecredentials] = useState<any>({
    CATEGORY_ID: '',
    CATEGORY_NAME: '',
    BUCKET_NAME: '',
  });
  const [proceedSavingFocusKeywords, setProceedSavingKeywords] = useState<SavingFocusKeywords>({
    bucket_name: '',
    proceed: false,
    focusKeywords: [],
    save_alert: '',
  });

  const { getBuckets, updateBucket } = useBucketService();
  const { mutate: mutateUpdateBucket } = updateBucket();
  const { getCategories, updateCategory, createCategory } = useCategoryService();
  const { createCategoryMutation } = createCategory();
  const { updateCategoryMutation } = updateCategory();

  const params_categories = () => {
    let parameters: any = undefined;

    if (selected_category || selected_category !== '') {
      parameters = {
        client_obj_id: locationState?.client_id,
        category_name: selected_category,
        platform: locationState?.platform,
      };
    } else if (locationState?.type === 'create-btn' && selected_category === '') {
      parameters = {
        client_obj_id: locationState?.client_id,
        category_name: selected_category,
        platform: locationState?.platform,
      };
    } else {
      parameters = {
        client_obj_id: locationState?.client_id,
        _id: locationState?._id,
        platform: locationState?.platform,
      };
    }

    return parameters;
  };

  const { data: get_category } = getCategories(params_categories());

  const get_passed_category = get_category?.data || [];

  const category_name_match = useCallback(() => {
    let result: any = undefined;
    if (selected_category === '' && locationState?.type === 'from-list') {
      result = get_passed_category[0]?.category_name;
    } else if (
      (locationState?.type === 'from-list' && selected_category) ||
      (selected_category && locationState?.type === 'create-btn')
    ) {
      result = selected_category;
    }
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_passed_category]);

  const bucketsID: any =
    selected_category || locationState?.type === 'from-list'
      ? get_passed_category.length &&
        get_passed_category
          ?.find((val: any) => val.category_name === category_name_match())
          ?.buckets.map((bucket: any) => bucket._id)
      : undefined;

  // const modified_bucketID =
  //   bucketsID !== undefined ? (bucketsID?.length > 10 ? bucketsID.slice(-10) : bucketsID) : bucketsID;

  const { data: bucketsData } = getBuckets({
    buckets_id: bucketsID,
    limit: 1000,
  });

  const get_buckets = bucketsData?.data || [];

  const { data: category_datas } = getCategories({
    client_obj_id: locationState?.client_id,
    platform: locationState?.platform,
    limit: 1000,
  });

  const get_categories_by_client_id = useMemo(() => {
    return category_datas?.data || [];
  }, [category_datas]);

  const initial_values = {
    bucket_name: '',
    category: locationState?.type === 'create-btn' ? locationState?.category_name : undefined,
    keywords: '',
    keyword_focus: [],
  };

  const add_bucket = useFormik({
    enableReinitialize: true,
    initialValues: initial_values,
    onSubmit: (values, { resetForm }) => {
      const categoryID = get_passed_category[0]?._id;

      const categoryName = values.category;
      const buckets_cred: any[] = [];

      get_buckets?.map((value: any) => {
        buckets_cred.push({ _id: value._id });
      });

      buckets_cred.push({
        client_obj_id: locationState?.client_id,
        bucket_name: values.bucket_name,
        platform: locationState?.platform,
        created_by: user._id,
        boolean_keywords: [],
        access_token: ls.get('user_admin')?.token || undefined,
      });

      setbucketsavecredentials({
        CATEGORY_ID: categoryID,
        CATEGORY_NAME: categoryName,
        BUCKET_NAME: values.bucket_name,
      });

      updateCategoryMutation.mutate(
        {
          client_obj_id: locationState?.client_id,
          _id: categoryID,
          category_name: categoryName,
          platform: locationState?.platform,
          buckets: buckets_cred,
          order_num:
            locationState?.type === 'create-btn'
              ? category_datas?.meta?.pagination
                ? category_datas?.meta?.pagination.total + 1
                : 0
              : locationState?.order_num,
          updated_by: user._id,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['category'], {
              refetchInactive: true,
            });
            queryClient.invalidateQueries(['bucket'], {
              refetchInactive: true,
            });
            queryClient.invalidateQueries(['keywords'], {
              refetchInactive: true,
            });
            set_boolean_keywords(values.keywords);

            if (values.keyword_focus.length > 0) {
              setProceedSavingKeywords({
                ...proceedSavingFocusKeywords,
                ['focusKeywords']: values.keyword_focus,
                ['save_alert']: 'focus',
              });
            } else {
              setProceedSavingKeywords({ ...proceedSavingFocusKeywords, ['save_alert']: 'no focus' });
            }
            setProceed(true);
            resetForm();
          },
          onError: (error: any) => {
            if (error?.response?.data?.server_response) {
              Alert.error(error.response.data.server_response);
            } else {
              Alert.error('Something Went Wrong');
            }
          },
        },
      );
    },
  });

  useEffect(() => {
    const execute_bucket_update = (values: any, focusKeywords?: any, focus?: boolean) => {
      const focus_keywords: any[] = focusKeywords
        ? focusKeywords.focusKeywords.map((focusKeyword) => ({
            boolean_keywords_id: focusKeywords.keywordID,
            keyword_focus: focusKeyword,
          }))
        : [];
      mutateUpdateBucket(
        {
          _id: values?._id,
          bucket_name: values?.bucket_name,
          platform: locationState?.platform,
          boolean_keywords: values.boolean_keywords,
          updated_by: user._id,
          client_obj_id: locationState.client_id,
          focus_keywords_id: focus_keywords,
          remove_focus_keywords_id: [],
        },
        {
          onSuccess: () => {
            if (
              (proceedSavingFocusKeywords.save_alert === 'focus' && focus && focus === true) ||
              proceedSavingFocusKeywords.save_alert === 'no focus'
            ) {
              Alert.success('Bucket Successfully Saved!');
              setLoading(false);
            }
            queryClient.invalidateQueries(['category'], {
              refetchInactive: true,
            });
            queryClient.invalidateQueries(['bucket'], {
              refetchInactive: true,
            });
            queryClient.invalidateQueries(['keywords'], {
              refetchInactive: true,
            });
            setProceed(false);
            const saving_focus_keywords: boolean = focus ? focus === true && true : false;
            if (!saving_focus_keywords) {
              setProceedSavingKeywords({
                ...proceedSavingFocusKeywords,
                ['bucket_name']: values?.bucket_name,
                ['proceed']: true,
              });
            }

            if (!proceedSavingFocusKeywords.focusKeywords.length || (focus && focus === true)) {
              if (proceedSavingFocusKeywords.save_alert === 'focus') {
                setProceedSavingKeywords({
                  ['bucket_name']: '',
                  ['proceed']: false,
                  ['focusKeywords']: [],
                  ['save_alert']: '',
                });
              }

              push(ROUTES.ADMIN.CLIENTS.BUCKETS.LIST, {
                _id: buckets_save_credentials.CATEGORY_ID,
                buckets: get_buckets,
                category_name: buckets_save_credentials.CATEGORY_NAME,
                client_id: locationState?.client_id,
                platform: locationState?.platform,
              });
            }
          },
          onError: (error: any) => {
            if (error?.response?.data?.server_response) {
              Alert.error(error.response.data.server_response);
            } else {
              Alert.error('Something Went Wrong');
            }
          },
        },
      );
    };

    const save_keywords = (param_bucket: any) => {
      const boolean_keywords: any[] = [];
      boolean_keywords.push({
        keywords_label: '',
        keywords: selected_boolean_keywords,
        created_by: user._id,
        access_token: ls.get('user_admin')?.token || undefined,
      });
      execute_bucket_update({
        bucket_name: param_bucket?.bucket_name,
        _id: param_bucket?._id,
        boolean_keywords: boolean_keywords,
      });
    };

    const update_bucket_save_focus_keywords = (values: any) => {
      execute_bucket_update(
        {
          bucket_name: values?.bucket_name,
          _id: values?._id,
          boolean_keywords: [
            {
              _id: values?.boolean_keywords_id[0]?._id,
            },
          ],
        },
        {
          keywordID: values?.boolean_keywords_id[0]?._id,
          focusKeywords: proceedSavingFocusKeywords.focusKeywords,
        },
        true,
      );
    };

    const bucketName =
      proceedSavingFocusKeywords.bucket_name && proceedSavingFocusKeywords.proceed
        ? proceedSavingFocusKeywords.bucket_name
        : buckets_save_credentials.BUCKET_NAME;

    const get_bucket = get_buckets.find((element) => element.bucket_name === bucketName);

    if (get_bucket && proceed === true && get_bucket.bucket_name === buckets_save_credentials.BUCKET_NAME) {
      save_keywords(get_bucket);
    }

    if (proceedSavingFocusKeywords.proceed !== false && proceedSavingFocusKeywords.bucket_name) {
      if (proceedSavingFocusKeywords.focusKeywords.length) {
        update_bucket_save_focus_keywords(get_bucket);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_buckets]);

  const create_category = useFormik({
    initialValues: {
      category_name: '',
    },
    onSubmit: (values, { resetForm }) => {
      createCategoryMutation.mutate(
        {
          client_obj_id: locationState?.client_id,
          category_name: values.category_name,
          platform: locationState?.platform,
          buckets: [],
          created_by: user._id,
          order_num: category_datas?.meta?.pagination
            ? category_datas?.meta?.pagination.total + (category_datas?.meta?.pagination.total - 1)
            : 0,
        },
        {
          onSuccess: () => {
            Alert.success('Category created');
            queryClient.invalidateQueries(['category'], {
              refetchInactive: true,
            });
            resetForm();
            setLoading(false);
          },
          onError: (error: any) => {
            if (error?.response?.data?.server_response) {
              Alert.error(error.response.data.server_response);
            } else {
              Alert.error('Something Went Wrong');
            }
          },
        },
      );
    },
  });

  const [category_modal_open, set_category_modal_open] = useState<boolean>(false);

  const category_selection = (e: any) => {
    if (e === 'create') {
      set_category_modal_open(true);
    } else {
      add_bucket.setFieldValue('category', e);
      set_selected_category(e);
    }
  };

  // ON PAGE LOAD SET TO FALSE FIRST TO STOP LOADING
  useEffect(() => {
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SpinnerWrapper spinning={loading} position="top">
      <Create
        formAdd={add_bucket}
        clientID={locationState?.client_id}
        categoryName={locationState?.category_name}
        categories={get_categories_by_client_id}
        category_selection={category_selection}
        categoryForm={create_category}
        categoryModal={category_modal_open}
        setCategoryModal={set_category_modal_open}
        type={locationState?.type}
        //
        setLoading={setLoading}
      />
    </SpinnerWrapper>
  );
}

export default Container;
