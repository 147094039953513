import { useQuery } from 'react-query';
import { useKeywordDao } from 'shared/dao/keywordDao';
import { IKeywordRequestPayload, IKeywordResponsePayload } from 'shared/interfaces/IKeyword';

const { getKeywords: getKeywordsDao, getKeywordShow: getKeywordShowDao } = useKeywordDao();

export const useKeywordService = () => {
  const getKeywords = (payload?: IKeywordRequestPayload) => {
    const { isLoading, isError, error, data, isFetching, isPreviousData, refetch } = useQuery<
      IKeywordResponsePayload,
      Error
    >(['keywords', payload], () => getKeywordsDao(payload));

    return {
      data,
      error,
      isLoading,
      isError,
      isFetching,
      isPreviousData,
      refetch,
    };
  };

  const getKeywordShow = (payload?: any) => {
    return useQuery<any, Error>(
      ['keywords', payload],
      async () => {
        return getKeywordShowDao(payload);
        // let data: any = [];
        // const currentPage = 1;

        // const response = await getKeywordShowDao(payload);

        // data = [...data, ...response.data];

        // const pages: number[] = [];

        // for (let i = currentPage + 1; i <= (response?.meta?.pagination?.total_pages || 1); i++) {
        //   pages.push(i);
        // }

        // if ((response?.meta?.pagination?.total_pages || 1) > 1) {
        //   const repsonses = await Promise.all(
        //     pages.map(async (page) => {
        //       const res = await getKeywordShowDao({
        //         ...payload,
        //         page,
        //       });

        //       return res;
        //     }),
        //   );

        //   for (const response of repsonses) {
        //     // Then after we get the remaining data for the remaining pages, we will now append it to 'data'
        //     data = [...data, ...response.data];
        //   }
        // }

        // return {
        //   ...response,
        //   data,
        // };
      },
      {
        enabled: !!payload?.keywords_id?.length,
      },
    );
  };
  return {
    getKeywords,
    getKeywordShow,
  };
};
