import React, { useState, useEffect } from 'react';
import { BucketWrapper, BucketCard } from '../List/Bucket.styled';
import Breadcrumbs from '../Components/Breadcrumbs';
// import { keywordsToBoolean } from 'shared/utils/keywordsToBoolean';
import { Button, Space, Form, Input, Typography, Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'shared/constants/ROUTES';
import KeywordsPreview from '../Components/KeywordsPreview';
import DisplayFocusKeywords from '../Components/DisplayFocusKeywords';
import DisplayTags from '../Components/DisplayTags';
import AddAdditionalKeywordsButton from '../Components/AddAdditionalKeywords/Button';
import AdditionalKeywords from '../Components/AddAdditionalKeywords/AdditionalKeywords';
import { CloseCircleFilled } from '@ant-design/icons';
import { IconContainer } from '../styles.styled';
import AdditionalKeywordsTagDisplay from '../Components/AddAdditionalKeywords/AdditionalKeywordsTagDisplay';
import { isEqual } from 'lodash';
import { categoryKeywordsToBoolean } from 'shared/utils/categoryKeywordsToBoolean';

const { Text } = Typography;

interface Props {
  updateForm: any;
  clientID: string;
  categoryName: string;
  addKeyword: boolean;
  type: string;
  cancel: { _id: string; buckets: any[]; category_name: string; client_id: string; platform: string };
  keyword_id: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ACTIONS = {
  KEYWORDS: {
    KEYWORD: 'keyword',
    ADDITIONAL: 'additional',
    EXCLUDED: 'excluded',
  },
  ADDITIONAL: {
    KEYWORD: 'additionalKeywordsMain',
    ADDITIONAL: 'additionalKeywordsAdditional',
    EXCLUDED: 'additionalKeywordsExcluded',
  },
};

const EditView = ({ setLoading, updateForm, clientID, categoryName, addKeyword, type, cancel, keyword_id }: Props) => {
  const { push } = useHistory();

  const charLimit = 4096;

  // const [keywordsData, setKeywordsData] = useState<string[]>([]);

  const [keywords, set_keywords] = useState<string[]>([]);
  const [additionalKeywords, set_additional_keywords] = useState<string[]>([]);
  const [excludedKeywords, set_excluded_keywords] = useState<string[]>([]);

  const [additionalKeywords1, setAdditionalKeywords1] = useState<string[]>([]);
  const [additionalKeywords2, setAdditionalKeywords2] = useState<string[]>([]);
  const [additionalKeywords3, setAdditionalKeywords3] = useState<string[]>([]);

  const [changeTags, setChangeTags] = useState<boolean>(false);
  const [addTags, setAddTags] = useState<boolean>(false);
  const [focusKeyword, setfocusKeyword] = useState<any[]>([]);
  const [focusKeywordAdditional, setfocusKeywordAdditional] = useState<string[]>([]);
  const [addAdditionalKeywords, setAddAdditionalKeywords] = useState<any>({
    main: [],
    additional: [],
    excluded: [],
  });

  useEffect(() => {
    if (!changeTags) {
      set_keywords(updateForm.values.keywords);
      set_additional_keywords(updateForm.values.additionalKeywords);
      set_excluded_keywords(updateForm.values.excludedKeywords);
      setAdditionalKeywords1(updateForm.values.additionalKeywordMain);
      setAdditionalKeywords2(updateForm.values.additionalKeywordAdditional);
      setAdditionalKeywords3(updateForm.values.additionalExcluded);

      const datas: any[] = [];
      if (updateForm.values.additionalKeywordMain.length > 0) {
        const add = addAdditionalKeywords.main;
        datas.push(add.concat(add.length + 1));
      } else {
        datas.push([]);
      }

      if (updateForm.values.additionalKeywordAdditional.length > 0) {
        const add = addAdditionalKeywords.additional;
        datas.push(add.concat(add.length + 1));
      } else {
        datas.push([]);
      }

      if (updateForm.values.additionalExcluded.length > 0) {
        const add = addAdditionalKeywords.excluded;
        datas.push(add.concat(add.length + 1));
      } else {
        datas.push([]);
      }

      if (
        addAdditionalKeywords.main.length === 0 &&
        addAdditionalKeywords.additional.length === 0 &&
        addAdditionalKeywords.excluded.length === 0
      ) {
        setAddAdditionalKeywords({ ['main']: datas[0], ['additional']: datas[1], ['excluded']: datas[2] });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateForm]);

  const [inputs, set_inputs] = useState<{
    keyword: string;
    additional: string;
    excluded: string;
    additionalKeywordsMain1: string;
    additionalKeywordsAdditional1: string;
    additionalKeywordsExcluded1: string;
  }>({
    keyword: '',
    additional: '',
    excluded: '',
    additionalKeywordsMain1: '',
    additionalKeywordsAdditional1: '',
    additionalKeywordsExcluded1: '',
  });

  const handle_inputs = (type: string, value: string) => {
    set_inputs({
      ...inputs,
      [type]: value,
    });
  };

  useEffect(() => {
    updateForm.setFieldValue(
      'modified_keywords',
      categoryKeywordsToBoolean({
        keywords,
        additionalKeywords,
        additionalKeywords1,
        additionalKeywords2,
        additionalKeywords3,
        excludedKeywords,
      }),
    );

    if (addTags) {
      setfocusKeyword(keywords);
      setfocusKeywordAdditional(additionalKeywords);
    } else {
      setfocusKeyword(keywords?.filter((focus: any) => updateForm.values.focus_keywords?.includes(focus.trim())));
      setfocusKeywordAdditional(
        additionalKeywords?.filter((focus: any) => updateForm.values.focus_keywords?.includes(focus.trim())),
      );
    }

    // setKeywordsData(keywords.concat(additionalKeywords, excludedKeywords));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywords, additionalKeywords, excludedKeywords, additionalKeywords1, additionalKeywords2, additionalKeywords3]);

  useEffect(() => {
    updateForm.setFieldValue('modified_focus_keywords', focusKeyword.concat(focusKeywordAdditional));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusKeyword, focusKeywordAdditional]);

  const handleAddAdditionalKeywords = (e: React.KeyboardEvent<HTMLTextAreaElement>, type: number) => {
    //&& keywordsData.length <= 500
    if (e.key === 'Enter' && inputs && updateForm.values.modified_keywords.length <= charLimit) {
      if (type === 11) {
        if (!additionalKeywords1.includes(inputs.additionalKeywordsMain1)) {
          const keyword_values = updateForm.values.additionalKeywordMain.concat(
            inputs.additionalKeywordsMain1.trim().split('\n'),
          );
          setAdditionalKeywords1([...new Set(keyword_values)] as string[]);
          // setKeywordsData((current) => [...current, inputs.additionalKeywordsMain1]);
          updateForm.setFieldValue('additionalKeywordMain', [...new Set(keyword_values)]);
          set_inputs({ ...inputs, ['additionalKeywordsMain1']: '' });
        }
      } else if (type === 21) {
        if (!additionalKeywords2.includes(inputs.additionalKeywordsAdditional1)) {
          const keyword_values = updateForm.values.additionalKeywordAdditional.concat(
            inputs.additionalKeywordsAdditional1.trim().split('\n'),
          );
          setAdditionalKeywords2([...new Set(keyword_values)] as string[]);
          // setKeywordsData((current) => [...current, inputs.additional2]);
          updateForm.setFieldValue('additionalKeywordAdditional', [...new Set(keyword_values)]);
          set_inputs({ ...inputs, ['additionalKeywordsAdditional1']: '' });
        }
      } else if (type === 31) {
        if (!additionalKeywords3.includes(inputs.additionalKeywordsExcluded1)) {
          const keyword_values = updateForm.values.additionalExcluded.concat(
            inputs.additionalKeywordsExcluded1.trim().split('\n'),
          );
          setAdditionalKeywords3([...new Set(keyword_values)] as string[]);
          // setKeywordsData((current) => [...current, inputs.additional3]);
          updateForm.setFieldValue('additionalExcluded', [...new Set(keyword_values)]);
          set_inputs({ ...inputs, ['additionalKeywordsExcluded1']: '' });
        }
      }
    }
  };

  const handleAddKeyword1 = (e: React.KeyboardEvent<HTMLTextAreaElement>, type: any) => {
    //&& keywordsData.length <= 500
    if (e.key === 'Enter' && updateForm.values.modified_keywords.length <= charLimit) {
      if (inputs) {
        if (type === ACTIONS.KEYWORDS.KEYWORD) {
          if (!keywords.includes(inputs.keyword)) {
            const keyword_values = updateForm.values.keywords.concat(inputs.keyword.trim().split('\n'));
            set_keywords([...new Set(keyword_values)] as string[]);
            // setKeywordsData((current) => [...current, inputs.keyword]);
            updateForm.setFieldValue('keywords', [...new Set(keyword_values)]);
            setfocusKeyword([...focusKeyword, inputs.keyword]);
            set_inputs({ ...inputs, ['keyword']: '' });
          }
        } else if (type === ACTIONS.KEYWORDS.ADDITIONAL) {
          if (!additionalKeywords.includes(inputs.additional)) {
            const additional_values = updateForm.values.additionalKeywords.concat(inputs.additional.trim().split('\n'));
            updateForm.setFieldValue('additionalKeywords', [...new Set(additional_values)]);
            set_additional_keywords([...new Set(additional_values)] as string[]);
            // setKeywordsData((current) => [...current, inputs.additional]);
            set_inputs({ ...inputs, additional: '' });
          }
        } else if (type === ACTIONS.KEYWORDS.EXCLUDED) {
          if (!excludedKeywords.includes(inputs.excluded)) {
            const additional_values = updateForm.values.excludedKeywords.concat(inputs.excluded.trim().split('\n'));
            updateForm.setFieldValue('excludedKeywords', [...new Set(additional_values)]);
            set_excluded_keywords([...new Set(additional_values)] as string[]);
            // setKeywordsData((current) => [...current, inputs.excluded]);
            set_inputs({ ...inputs, excluded: '' });
          }
        }
      }
    }
    setAddTags(true);
  };

  const handleRemoveKeyword = (
    value: string,
    type: 'keyword' | 'additional' | 'excluded' | 'AdditionalMain' | 'AddAdditional' | 'AddExcluded',
  ) => {
    if (type === 'keyword') {
      const filteredKeywords = keywords.filter((i) => i !== value);
      set_keywords(filteredKeywords);
      updateForm.setFieldValue('keywords', filteredKeywords);
    } else if (type === 'additional') {
      const filteredAdditionalKeywords = additionalKeywords.filter((i) => i !== value);
      set_additional_keywords(filteredAdditionalKeywords);
      updateForm.setFieldValue('additionalKeywords', filteredAdditionalKeywords);
    } else if (type === 'excluded') {
      const filteredExcludedKeywords = excludedKeywords.filter((i) => i !== value);
      set_excluded_keywords(filteredExcludedKeywords);
      updateForm.setFieldValue('excludedKeywords', filteredExcludedKeywords);
    } else if (type === 'AdditionalMain') {
      const filteredAdditionalMain = additionalKeywords1.filter((i) => i !== value);
      setAdditionalKeywords1(filteredAdditionalMain);
      updateForm.setFieldValue('additionalKeywordMain', filteredAdditionalMain);
    } else if (type === 'AddAdditional') {
      const filteredAddAdditional = additionalKeywords2.filter((i) => i !== value);
      setAdditionalKeywords2(filteredAddAdditional);
      updateForm.setFieldValue('additionalKeywordAdditional', filteredAddAdditional);
    } else if (type === 'AddExcluded') {
      const filteredAddExcluded = additionalKeywords3.filter((i) => i !== value);
      setAdditionalKeywords3(filteredAddExcluded);
      updateForm.setFieldValue('additionalExcluded', filteredAddExcluded);
    }
    // setKeywordsData(keywordsData.filter((i) => i !== value));
    setChangeTags(true);
  };

  const onchange_focus_keywords = (type: string, e: any) => {
    if (type === 'main') setfocusKeyword(e);
    if (type === 'additional') setfocusKeywordAdditional(e);
  };

  const add_additional_keywords = (type: string) => {
    const add: any =
      type === 'main'
        ? addAdditionalKeywords.main
        : type === 'additional'
        ? addAdditionalKeywords.additional
        : addAdditionalKeywords.excluded;
    const add_value = add.concat(add.length + 1);
    setAddAdditionalKeywords({
      ...addAdditionalKeywords,
      [type]: add.length === 1 ? add : add.length < 1 ? add_value : add,
    });
  };

  const additional_keywords_inputs: any[] = [
    inputs.additionalKeywordsMain1,
    inputs.additionalKeywordsAdditional1,
    inputs.additionalKeywordsExcluded1,
  ];
  const additional_keywords_keys: any[] = [
    ['additionalKeywordsMain1', 'additionalKeywordsMain2', 'additionalKeywordsMain3'],
    ['additionalKeywordsAdditional1', 'additionalKeywordsAdditional2', 'additionalKeywordsAdditional3'],
    ['additionalKeywordsExcluded1', 'additionalKeywordsExcluded2', 'additionalKeywordsExcluded3'],
  ];

  const remove_additional_keywords = (type: string, value: number) => {
    let keyType = '';
    let inputKeyType: any = undefined;
    if (type === 'AdditionalMain') {
      keyType = 'main';
      inputKeyType = additional_keywords_keys[0][value === 1 ? 0 : value === 2 ? 1 : 2];
      // const filter_data = addAdditionalMain.filter((item) => !item.includes(`1${value}_`));
      // const filter_data = addAdditionalMain.filter((item) => !item.includes(`1${value}`));
      setAdditionalKeywords1([]);
      updateForm.setFieldValue('additionalKeywordMain', []);
    } else if (type === 'AddAdditional') {
      keyType = 'additional';
      inputKeyType = additional_keywords_keys[1][value === 1 ? 0 : value === 2 ? 1 : 2];
      setAdditionalKeywords2([]);
      updateForm.setFieldValue('additionalKeywordAdditional', []);
    } else if (type === 'AddExcluded') {
      keyType = 'excluded';
      inputKeyType = additional_keywords_keys[2][value === 1 ? 0 : value === 2 ? 1 : 2];
      setAdditionalKeywords3([]);
      updateForm.setFieldValue('additionalExcluded', []);
    }

    setAddAdditionalKeywords({
      ...addAdditionalKeywords,
      [keyType]: addAdditionalKeywords.main.filter((val) => val !== value),
    });

    set_inputs({ ...inputs, [inputKeyType]: '' });
  };

  // const handleLimit = () => {
  //   if (inputLimit.keywordLimit < 1) {
  //     setInputLimit({ ...inputLimit, keywordLimit: 4096 });
  //   } else {
  //     const newKeywordLimit = inputLimit.keywordLimit - 1;
  //     setInputLimit({ ...inputLimit, keywordLimit: newKeywordLimit });
  //   }
  // };
  return (
    <BucketWrapper>
      <BucketCard>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 30,
          }}
        >
          <Breadcrumbs
            clientID={clientID}
            categoryName={categoryName}
            name={addKeyword ? (keyword_id ? 'Update Keyword' : 'Create Keyword') : 'Update Bucket'}
          />
          <Button onClick={() => push(ROUTES.ADMIN.CLIENTS.CATEGORY, { id: clientID, default_tab: 'categories' })}>
            Back To Category
          </Button>
        </div>
        <div>
          <Form
            name="bucketCreate"
            autoComplete="off"
            layout="vertical"
            fields={[
              {
                name: 'bucket_name',
                value: updateForm.values.bucket_name,
              },
              {
                name: 'main_keywords',
                value: inputs.keyword,
              },
              {
                name: 'additional_keywords',
                value: inputs.additional,
              },
              {
                name: 'excluded_keywords',
                value: inputs.excluded,
              },
              {
                name: 'category_name',
                value: categoryName,
              },
            ]}
          >
            {!addKeyword && (
              <Space direction="horizontal" size="large">
                <Form.Item
                  label="Bucket Name"
                  name="bucket_name"
                  rules={[{ required: true, message: 'bucket name required!' }]}
                >
                  <Input
                    placeholder="Bucket name here"
                    style={{ width: 300 }}
                    value={updateForm.values.bucket_name}
                    onChange={(e) => updateForm.setFieldValue('bucket_name', e.target.value)}
                  />
                </Form.Item>

                <Form.Item label="Category" name="category_name" rules={[{ required: true, message: '' }]}>
                  <Input
                    style={{ width: 300, backgroundColor: '#ffffff', color: '#000000' }}
                    value={categoryName}
                    disabled
                  />
                </Form.Item>
              </Space>
            )}
            {type !== 'update' && (
              <>
                <div
                  style={{
                    marginTop: 15,
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Text>Keyword Sting Preview</Text>
                    <p style={{ color: '#808080', fontSize: '0.875em' }}>
                      Character limit: {updateForm.values.modified_keywords.length} / {charLimit.toLocaleString()}
                    </p>
                  </div>
                  <KeywordsPreview
                    keywords={keywords}
                    additionalKeywords={additionalKeywords}
                    additionalKeywords1={additionalKeywords1}
                    additionalKeywords2={additionalKeywords2}
                    additionalKeywords3={additionalKeywords3}
                    excludedKeywords={excludedKeywords}
                  />
                </div>
                <div>
                  <Row gutter={16}>
                    <Col span={8} className="gutter-row">
                      <Form.Item label="Main Keywords" name="main_keywords" rules={[{ required: true, message: '' }]}>
                        <Input.TextArea
                          placeholder="Enter main keywords"
                          value={inputs.keyword}
                          onChange={(e) => {
                            handle_inputs(ACTIONS.KEYWORDS.KEYWORD, e.target.value);
                            // handleLimit();
                          }}
                          autoSize
                          onKeyUp={(e) => handleAddKeyword1(e, ACTIONS.KEYWORDS.KEYWORD)}
                        />
                        <div style={{ marginTop: 12 }}>
                          <DisplayTags
                            type="keyword"
                            iterateData={keywords}
                            tagColor="#605aa6"
                            handleRemoveKeyword={handleRemoveKeyword}
                          />
                        </div>
                      </Form.Item>
                    </Col>
                    <Col span={8} className="gutter-row">
                      <Form.Item label="Additional Keywords" name="additional_keywords" rules={[{ message: '' }]}>
                        <Input.TextArea
                          autoSize
                          placeholder="Enter additional keywords"
                          value={inputs.additional}
                          onChange={(e) => {
                            handle_inputs(ACTIONS.KEYWORDS.ADDITIONAL, e.target.value);
                            // handleLimit();
                          }}
                          onKeyUp={(e) => handleAddKeyword1(e, ACTIONS.KEYWORDS.ADDITIONAL)}
                        />
                        <div style={{ marginTop: 12 }}>
                          <DisplayTags
                            type="additional"
                            iterateData={additionalKeywords}
                            tagColor="#00cd6e"
                            handleRemoveKeyword={handleRemoveKeyword}
                          />
                        </div>
                      </Form.Item>
                    </Col>
                    <Col span={8} className="gutter-row">
                      <Form.Item label="Excluded Keywords" name="excluded_keywords" rules={[{ message: '' }]}>
                        <Input.TextArea
                          autoSize
                          placeholder="Enter excluded keywords"
                          value={inputs.excluded}
                          onChange={(e) => {
                            handle_inputs(ACTIONS.KEYWORDS.EXCLUDED, e.target.value);
                            // handleLimit();
                          }}
                          onKeyUp={(e) => handleAddKeyword1(e, ACTIONS.KEYWORDS.EXCLUDED)}
                        />
                        <div style={{ marginTop: 12 }}>
                          <DisplayTags
                            type="excluded"
                            iterateData={excludedKeywords}
                            tagColor="#f44336"
                            handleRemoveKeyword={handleRemoveKeyword}
                          />
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={8} className="gutter-row">
                      {addAdditionalKeywords.main.length > 0 &&
                        addAdditionalKeywords.main?.map((value: any) => (
                          <div key={value} style={{ marginBottom: 8 }}>
                            <label style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}>
                              Additional Keywords (optional)
                              <IconContainer
                                style={{ color: '#00cd6e' }}
                                onClick={() => {
                                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                  remove_additional_keywords('AdditionalMain', value);
                                }}
                              >
                                <CloseCircleFilled style={{ marginLeft: 4 }} size={2} />
                              </IconContainer>
                            </label>
                            <AdditionalKeywords
                              value={additional_keywords_inputs[0]}
                              type={'additionalKeywordsMain1'}
                              // onChange={[handle_inputs, handleLimit]}
                              onChange={[handle_inputs]}
                              onKeyUp={handleAddAdditionalKeywords}
                            />
                            <div style={{ marginTop: 8 }} key={value}>
                              <AdditionalKeywordsTagDisplay
                                type={['AdditionalMain', value]}
                                iterateData={additionalKeywords1}
                                tagColor="lime"
                                handleRemoveKeyword={handleRemoveKeyword}
                              />
                            </div>
                          </div>
                        ))}
                    </Col>
                    <Col span={8} className="gutter-row">
                      {addAdditionalKeywords.additional.length > 0 &&
                        addAdditionalKeywords.additional?.map((value: any) => (
                          <div key={value} style={{ marginBottom: 8 }}>
                            <label style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}>
                              Additional Keywords (optional)
                              <IconContainer
                                style={{ color: '#00cd6e' }}
                                onClick={() => {
                                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                  remove_additional_keywords('AddAdditional', value);
                                }}
                              >
                                <CloseCircleFilled style={{ marginLeft: 4 }} size={2} />
                              </IconContainer>
                            </label>
                            <AdditionalKeywords
                              value={additional_keywords_inputs[1]}
                              type={'additionalKeywordsAdditional1'}
                              // onChange={[handle_inputs, handleLimit]}
                              onChange={[handle_inputs]}
                              onKeyUp={handleAddAdditionalKeywords}
                            />
                            <div style={{ marginTop: 8 }} key={value}>
                              <AdditionalKeywordsTagDisplay
                                type={['AddAdditional', value]}
                                iterateData={additionalKeywords2}
                                tagColor="lime"
                                handleRemoveKeyword={handleRemoveKeyword}
                              />
                            </div>
                          </div>
                        ))}
                    </Col>
                    <Col span={8} className="gutter-row">
                      {addAdditionalKeywords.excluded.length > 0 &&
                        addAdditionalKeywords.excluded?.map((value: any) => (
                          <div key={value} style={{ marginBottom: 8 }}>
                            <label style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}>
                              Additional Keywords (optional)
                              <IconContainer
                                style={{ color: '#00cd6e' }}
                                onClick={() => {
                                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                  remove_additional_keywords('AddExcluded', value);
                                }}
                              >
                                <CloseCircleFilled style={{ marginLeft: 4 }} size={2} />
                              </IconContainer>
                            </label>
                            <AdditionalKeywords
                              value={additional_keywords_inputs[2]}
                              type={'additionalKeywordsExcluded1'}
                              // onChange={[handle_inputs, handleLimit]}
                              onChange={[handle_inputs]}
                              onKeyUp={handleAddAdditionalKeywords}
                            />
                            <div style={{ marginTop: 8 }} key={value}>
                              <AdditionalKeywordsTagDisplay
                                type={['AddExcluded', value]}
                                iterateData={additionalKeywords3}
                                tagColor="lime"
                                handleRemoveKeyword={handleRemoveKeyword}
                              />
                            </div>
                          </div>
                        ))}
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={8} className="gutter-row">
                      <AddAdditionalKeywordsButton
                        type={0}
                        addKeywords={[
                          addAdditionalKeywords.main,
                          addAdditionalKeywords.additional,
                          addAdditionalKeywords.excluded,
                        ]}
                        onClickType={['main', 'additional', 'excluded']}
                        onChange={add_additional_keywords}
                        disabled={updateForm.values.additionalKeywords.length === 0}
                      />
                    </Col>
                    <Col span={8} className="gutter-row">
                      <AddAdditionalKeywordsButton
                        type={1}
                        addKeywords={[
                          addAdditionalKeywords.main,
                          addAdditionalKeywords.additional,
                          addAdditionalKeywords.excluded,
                        ]}
                        onClickType={['main', 'additional', 'excluded']}
                        onChange={add_additional_keywords}
                        disabled={updateForm.values.additionalKeywords.length === 0}
                      />
                    </Col>
                    <Col span={8} className="gutter-row">
                      <AddAdditionalKeywordsButton
                        type={2}
                        addKeywords={[
                          addAdditionalKeywords.main,
                          addAdditionalKeywords.additional,
                          addAdditionalKeywords.excluded,
                        ]}
                        onClickType={['main', 'additional', 'excluded']}
                        onChange={add_additional_keywords}
                        disabled={updateForm.values.additionalKeywords.length === 0}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16} style={{ marginTop: 10 }}>
                    <Col span={8} className="gutter-row">
                      <Form.Item name="keywords" label={keywords.length ? 'Main Keywords' : ''}>
                        <Space direction="horizontal">
                          <DisplayFocusKeywords
                            passedType={'main'}
                            checkedValue={focusKeyword}
                            iterateValues={keywords}
                            focus_Keywords={onchange_focus_keywords}
                          />
                        </Space>
                      </Form.Item>
                    </Col>
                    <Col span={8} className="gutter-row">
                      <Form.Item
                        label={additionalKeywords.length ? 'Main Keywords' : ''}
                        name="main_additional_keywords"
                      >
                        <Space direction="horizontal">
                          <DisplayFocusKeywords
                            passedType={'additional'}
                            checkedValue={focusKeywordAdditional}
                            iterateValues={additionalKeywords}
                            focus_Keywords={onchange_focus_keywords}
                          />
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </Form>
        </div>
      </BucketCard>
      <div style={{ marginTop: 16 }}>
        <Space size="middle">
          <Button
            onClick={() =>
              push(ROUTES.ADMIN.CLIENTS.BUCKETS.LIST, {
                _id: cancel._id,
                buckets: cancel.buckets,
                category_name: cancel.category_name,
                client_id: cancel.client_id,
                platform: cancel.platform,
              })
            }
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              updateForm.handleSubmit();
              setLoading(true);
            }}
            disabled={
              isEqual(
                updateForm.values.keywords.concat(
                  updateForm.values.additionalExcluded,
                  updateForm.values.additionalKeywordAdditional,
                  updateForm.values.additionalKeywordMain,
                  updateForm.values.additionalKeywords,
                  updateForm.values.excludedKeywords,
                  [updateForm.values.bucket_name],
                  updateForm.values.modified_focus_keywords,
                ),
                updateForm.initialValues.keywords.concat(
                  updateForm.initialValues.additionalExcluded,
                  updateForm.initialValues.additionalKeywordAdditional,
                  updateForm.initialValues.additionalKeywordMain,
                  updateForm.initialValues.additionalKeywords,
                  updateForm.initialValues.excludedKeywords,
                  [updateForm.initialValues.bucket_name],
                  updateForm.initialValues.focus_keywords,
                ),
              ) || updateForm.values.keywords.length < 1
            }
          >
            {addKeyword ? (keyword_id ? 'Update' : 'Save') : 'Update'}
          </Button>
        </Space>
      </div>
    </BucketWrapper>
  );
};

export default EditView;
