export const ENDPOINTS = {
  PUBLICATIONS: '/publisher',
  MEDIA_SOURCES: '/mediasource',
  MULTIPLIERS: '/multiplier',
  AUTHORS: '/author',
  AV_AUTOMATION: '/audio-video/automation-setup',
  PROGRAM_SETUP: '/audio-video/automation-setup',
  VIDEO_CLIP: '/audio-video/video',
  RADIO_CLIP: '/audio-video/audio',
  ARTICLES: '/articles-api',
  CHANNEL: '/audio-video/channel',
  PROGRAM: '/audio-video/program',
  TIMESLOT: '/audio-video/timeslot',
  KEYWORDS: '/keyword',
  CORPUS: '/articles-api',
  CORPUS_RECORD: '/corpus_record',
  CLIENT: '/client',
  CATEGORY: '/category',
  COUNTRY: '/country',
  MONITORING: '/audio-video/facebook-video-jobs',
  MONITORING_STATUS: '/audio-video/facebook-video-jobs/monitoring_count',
  ONLINE_NEWS_BLOGS: '/online-news-blogs',
  PRINT_MEDIA: '/print',
  MEDIAWATCH: {
    SIMPLEMOVINGARTICLES: '/mediawatch/simple_moving_average',
    ENTITY: '/articles-api/entities',
    CONTROL: '/audio-video/program_comparison',
  },
  WEBSITEGARDEN: '/website_garden',
  MACHINE_IP: '/audio-video/ip',
  MAILER_MONITORING: 'mailer/monitor',
  MAILER_MONITOR_RESEND: 'mailer/resend',
  MAILER: {
    ALL: '/mailer',
    SINGLE: '/mailer/show',
    CREATE: '/mailer/create',
    UPDATE: '/mailer/update',
    DELETE: '/mailer/delete',
    RESEND: '/mailer/custom_resend',
  },
  MAILER_TEMPLATES: {
    ALL: '/mailer_template',
    SINGLE: '/mailer_template/show',
    CREATE: '/mailer_template/create',
    UPDATE: '/mailer_template/update',
    DELETE: '/mailer_template/delete',
  },
  BUCKET: '/bucket',
  INFLUENCERS: '/influencer',
  SHAREDVIEW_INFLUENCER: 'sharedview/influencers',
  USERS: '/users',
  SOCIALMEDIA: '/socialmedia',
  CLIENT_SUBSCRIPTION: {
    UPDATE: '/client/subscriptions/update',
    SINGLE: '/client/subscriptions/show',
  },
};
