import { CloseCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Space, Typography } from 'antd';
import { StyledTag } from 'pages/AdminPage/Corpus/CorpusKeyword/CorpusKeyword.styled';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'shared/constants/ROUTES';
import { categoryKeywordsToBoolean } from 'shared/utils/categoryKeywordsToBoolean';
import { keywordPreviewParser } from 'shared/utils/keywordPreviewParser_v2';
import CreateCategories from '../../Categories/Components/CreateCategories';
import Breadcrumbs from '../Components/Breadcrumbs';
import DisplayFocusKeywords from '../Components/DisplayFocusKeywords';
import { BucketCard, BucketWrapper } from '../List/Bucket.styled';
import { IconContainer } from '../styles.styled';

const { Text } = Typography;
const { Option } = Select;

interface Props {
  formAdd: any;
  clientID: string;
  categoryName?: string | undefined;
  categories: any;
  category_selection: (e: any) => void;
  categoryForm: any;
  categoryModal: boolean;
  setCategoryModal: React.Dispatch<React.SetStateAction<boolean>>;
  type: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
const Create = ({
  formAdd,
  clientID,
  categoryName,
  categories,
  category_selection,
  categoryForm,
  categoryModal,
  setCategoryModal,
  type,
  setLoading,
}: Props) => {
  const { push } = useHistory();

  // const [keywordsData, setkeywordsData] = useState<string[]>([]);
  const charLimit = 4096;

  const [keywords, setKeywords] = useState<string[]>([]);
  const [additionalKeywords, setAddionalKeywords] = useState<string[]>([]);
  const [additionalKeywords1, setAddionalKeywords1] = useState<string[]>([]);
  const [additionalKeywords2, setAddionalKeywords2] = useState<string[]>([]);
  const [additionalKeywords3, setAddionalKeywords3] = useState<string[]>([]);
  const [isOpenKeywords1, setIsOpenKeywords1] = useState<boolean>(false);
  const [isOpenKeywords2, setIsOpenKeywords2] = useState<boolean>(false);
  const [isOpenKeywords3, setIsOpenKeywords3] = useState<boolean>(false);
  const [excludedKeywords, setExcludedKeywords] = useState<string[]>([]);
  const [focusKeywordsMain, setFocusKeywordsMain] = useState<string[]>([]);
  const [focusKeywordsAdditional, setFocusKeywordsAdditional] = useState<string[]>([]);
  const [inputText, setInputText] = useState<{
    keyword: string;
    additionalKeyword: string;
    additionalKeyword1: string;
    additionalKeyword2: string;
    additionalKeyword3: string;
    excludedKeyword: string;
  }>({
    keyword: '',
    additionalKeyword: '',
    additionalKeyword1: '',
    additionalKeyword2: '',
    additionalKeyword3: '',
    excludedKeyword: '',
  });

  // const clearStateFields = () => {
  //   setKeywords([]);
  //   setAddionalKeywords([]);
  //   setExcludedKeywords([]);
  // };

  const [
    parsedKeywords,
    parsedAdditionalKeywords,
    parsedAdditionalKeywords1,
    parsedAdditionalKeywords2,
    parsedAdditionalKeywords3,
    parsedExcludedKeywords,
  ] = keywordPreviewParser({
    keywords,
    additionalKeywords,
    additionalKeywords1,
    additionalKeywords2,
    additionalKeywords3,
    excludedKeywords,
  });

  useEffect(() => {
    if (
      keywords.length > 0 ||
      additionalKeywords.length > 0 ||
      additionalKeywords1.length > 0 ||
      additionalKeywords2.length > 0 ||
      additionalKeywords3.length > 0 ||
      excludedKeywords.length > 0
    ) {
      formAdd.setFieldValue(
        'keywords',
        categoryKeywordsToBoolean({
          keywords,
          additionalKeywords,
          additionalKeywords1,
          additionalKeywords2,
          additionalKeywords3,
          excludedKeywords,
        }),
      );
    }

    setFocusKeywordsMain(keywords);
    setFocusKeywordsAdditional(additionalKeywords);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywords, additionalKeywords, additionalKeywords1, additionalKeywords2, additionalKeywords3, excludedKeywords]);

  const handleAddKeyword = (
    e: React.KeyboardEvent<HTMLTextAreaElement>,
    type:
      | 'keyword'
      | 'additionalKeyword'
      | 'additionalKeyword1'
      | 'additionalKeyword2'
      | 'additionalKeyword3'
      | 'excludedKeyword',
  ) => {
    if (e.key === 'Enter' && formAdd.values.keywords.length <= charLimit) {
      if (type === 'keyword' && inputText.keyword) {
        setKeywords((curr) => [...new Set([...curr, ...inputText.keyword.trim().split('\n')])]);
        setInputText({ ...inputText, keyword: '' });
      } else if (type === 'additionalKeyword' && inputText.additionalKeyword) {
        setAddionalKeywords((curr) => [...new Set([...curr, ...inputText.additionalKeyword.trim().split('\n')])]);
        setInputText({ ...inputText, additionalKeyword: '' });
      } else if (type === 'additionalKeyword1' && inputText.additionalKeyword1) {
        setAddionalKeywords1((curr) => [...new Set([...curr, ...inputText.additionalKeyword1.trim().split('\n')])]);
        setInputText({ ...inputText, additionalKeyword1: '' });
      } else if (type === 'additionalKeyword2' && inputText.additionalKeyword2) {
        setAddionalKeywords2((curr) => [...new Set([...curr, ...inputText.additionalKeyword2.trim().split('\n')])]);
        setInputText({ ...inputText, additionalKeyword2: '' });
      } else if (type === 'additionalKeyword3' && inputText.additionalKeyword3) {
        setAddionalKeywords3((curr) => [...new Set([...curr, ...inputText.additionalKeyword3.trim().split('\n')])]);
        setInputText({ ...inputText, additionalKeyword3: '' });
      } else {
        setExcludedKeywords((curr) => [...new Set([...curr, ...inputText.excludedKeyword.trim().split('\n')])]);
        setInputText({ ...inputText, excludedKeyword: '' });
      }
    }
  };

  const focus_Keywords = (type: string, e: any) => {
    [setFocusKeywordsMain, setFocusKeywordsAdditional][type === 'main' ? 0 : 1](e);
  };

  useEffect(() => {
    formAdd.setFieldValue('keyword_focus', focusKeywordsMain.concat(focusKeywordsAdditional));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusKeywordsMain, focusKeywordsAdditional]);

  const handleOk = (action: string) => {
    if (action === 'create') {
      setCategoryModal(false);
      categoryForm.handleSubmit();
    }
  };

  const handleCancel = (action: string) => {
    if (action === 'create') {
      setCategoryModal(false);
    }
  };

  const handleRemoveKeyword = (
    index: number,
    type:
      | 'keyword'
      | 'additionalKeyword'
      | 'additionalKeyword1'
      | 'additionalKeyword2'
      | 'additionalKeyword3'
      | 'excludedKeyword',
  ) => {
    if (type === 'keyword') {
      const filteredKeywords = keywords.filter((_, i) => i !== index);
      setKeywords(filteredKeywords);
    } else if (type === 'additionalKeyword') {
      const filteredAdditionalKeywords = additionalKeywords.filter((_, i) => i !== index);
      setAddionalKeywords(filteredAdditionalKeywords);
    } else if (type === 'additionalKeyword1') {
      const filteredAdditionalKeywords1 = additionalKeywords1.filter((_, i) => i !== index);
      setAddionalKeywords1(filteredAdditionalKeywords1);
    } else if (type === 'additionalKeyword2') {
      const filteredAdditionalKeywords2 = additionalKeywords2.filter((_, i) => i !== index);
      setAddionalKeywords2(filteredAdditionalKeywords2);
    } else if (type === 'additionalKeyword3') {
      const filteredAdditionalKeywords3 = additionalKeywords3.filter((_, i) => i !== index);
      setAddionalKeywords3(filteredAdditionalKeywords3);
    } else {
      const filteredExcludedKeywords = excludedKeywords.filter((_, i) => i !== index);
      setExcludedKeywords(filteredExcludedKeywords);
    }

    formAdd.setFieldValue(
      'keywords',
      categoryKeywordsToBoolean({
        keywords,
        additionalKeywords,
        additionalKeywords1,
        additionalKeywords2,
        additionalKeywords3,
        excludedKeywords,
      }),
    );
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputText({ ...inputText, [e.target.name]: e.target.value });
  };

  return (
    <BucketWrapper>
      <BucketCard>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 30,
          }}
        >
          <Breadcrumbs
            categoryName={categoryName ? categoryName : type === 'create-btn' && formAdd.values.category}
            name={'Create Bucket'}
            clientID={clientID}
          />
          <Button onClick={() => push(ROUTES.ADMIN.CLIENTS.CATEGORY, { id: clientID, default_tab: 'categories' })}>
            Back To Category
          </Button>
        </div>
        <div>
          <Form name="bucketCreate" autoComplete="off" layout="vertical">
            <Space direction="horizontal" size="large">
              <Form.Item
                label="Bucket Name"
                name="bucket_name"
                rules={[{ required: true, message: 'bucket name required!' }]}
              >
                <Input
                  placeholder="Bucket name here"
                  style={{ width: 300 }}
                  value={formAdd.values.bucket_name}
                  onChange={(e) => formAdd.setFieldValue('bucket_name', e.target.value)}
                />
              </Form.Item>

              <Form.Item label="Category" rules={[{ required: true, message: '' }]}>
                <Select
                  placeholder={type === 'create-btn' ? 'Select Categories' : undefined}
                  style={{ width: 250 }}
                  value={formAdd.values.category || categoryName}
                  optionFilterProp="children"
                  filterSort={(optionA, optionB) =>
                    (optionA?.category_name ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.category_name ?? '').toLowerCase())
                  }
                  onChange={(e) => category_selection(e)}
                >
                  <Option key="create" value="create">
                    Create Category
                  </Option>
                  {categories?.reverse().map((value: any) => (
                    <Option key={value._id} value={value.category_name}>
                      {value.category_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Space>
            <div
              style={{
                marginTop: 15,
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text>Keyword String Preview</Text>
                <p style={{ color: '#808080', fontSize: 13 }}>
                  Character limit: {formAdd.values.keywords.length} / {charLimit.toLocaleString()}
                </p>
              </div>
              <div style={{ marginTop: 10, marginBottom: 18 }}>
                {parsedKeywords ||
                parsedAdditionalKeywords ||
                parsedExcludedKeywords ||
                parsedAdditionalKeywords1 ||
                parsedAdditionalKeywords2 ||
                parsedAdditionalKeywords3 ? (
                  <Text
                    style={{
                      backgroundColor: '#fafafa',
                      padding: 5,
                    }}
                  >
                    <span style={{ color: '#605aa6' }}>{parsedKeywords}</span>
                    <span style={{ color: '#00cd6e' }}>{parsedAdditionalKeywords}</span>
                    <span style={{ color: '#00cd5e' }}>{parsedAdditionalKeywords1}</span>
                    <span style={{ color: '#00cd4e' }}>{parsedAdditionalKeywords2}</span>
                    <span style={{ color: '#00cd3e' }}>{parsedAdditionalKeywords3}</span>
                    <span style={{ color: '#f44336' }}>{parsedExcludedKeywords}</span>
                  </Text>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div>
              <Row gutter={[16, 12]}>
                <Col span={8} className="gutter-row">
                  <Form.Item label="Main Keywords" name="keyword" rules={[{ required: true, message: '' }]}>
                    <Input.TextArea
                      placeholder="Enter main keywords"
                      value={inputText.keyword}
                      name="keyword"
                      onChange={handleInputChange}
                      autoSize
                      onKeyUp={(e) => handleAddKeyword(e, 'keyword')}
                    />
                    <div style={{ marginTop: 12 }}>
                      {keywords.length > 0 && (
                        <Space wrap={true}>
                          {keywords.map((keyword, index) => (
                            <StyledTag
                              key={index}
                              closable
                              color="#605AA6"
                              closeIcon={<CloseCircleOutlined style={{ color: '#ffffff', fontSize: 14 }} />}
                              onClose={(e) => {
                                e.preventDefault();
                                handleRemoveKeyword(index, 'keyword');
                              }}
                            >
                              {keyword}
                            </StyledTag>
                          ))}
                        </Space>
                      )}
                    </div>
                  </Form.Item>
                </Col>
                <Col span={8} className="gutter-row">
                  {/* <MMInput
                    type="text"
                    $fullWidth
                    size="middle"
                    label="Additional Keywords"
                    onKeyUp={(e) => handleAddKeyword(e, 'additionalKeyword')}
                    value={inputText.additionalKeyword}
                    name="additionalKeyword"
                    onChange={handleInputChange}
                  /> */}
                  <Form.Item label="Additional Keywords" name="additionalKeyword">
                    <Input.TextArea
                      placeholder="Enter additional keywords"
                      value={inputText.additionalKeyword}
                      name="additionalKeyword"
                      onChange={handleInputChange}
                      autoSize
                      onKeyUp={(e) => handleAddKeyword(e, 'additionalKeyword')}
                    />
                    <div style={{ marginTop: 12 }}>
                      {additionalKeywords.length > 0 && (
                        <Space wrap={true}>
                          {additionalKeywords.map((keyword, index) => (
                            <StyledTag
                              key={index}
                              closable
                              color="#00CD6E"
                              closeIcon={<CloseCircleOutlined style={{ color: '#ffffff', fontSize: 14 }} />}
                              onClose={(e) => {
                                e.preventDefault();
                                handleRemoveKeyword(index, 'additionalKeyword');
                              }}
                            >
                              {keyword}
                            </StyledTag>
                          ))}
                        </Space>
                      )}
                    </div>
                  </Form.Item>
                </Col>
                <Col span={8} className="gutter-row">
                  <Form.Item label="Excluded Keywords" name="excludedKeyword">
                    <Input.TextArea
                      placeholder="Enter excluded keywords"
                      value={inputText.excludedKeyword}
                      name="excludedKeyword"
                      onChange={handleInputChange}
                      autoSize
                      onKeyUp={(e) => handleAddKeyword(e, 'excludedKeyword')}
                    />
                    <div style={{ marginTop: 12 }}>
                      {excludedKeywords.length > 0 && (
                        <Space wrap={true}>
                          {excludedKeywords.map((keyword, index) => (
                            <StyledTag
                              key={index}
                              closable
                              color="#F44336"
                              closeIcon={<CloseCircleOutlined style={{ color: '#ffffff', fontSize: 14 }} />}
                              onClose={(e) => {
                                e.preventDefault();
                                handleRemoveKeyword(index, 'excludedKeyword');
                              }}
                            >
                              {keyword}
                            </StyledTag>
                          ))}
                        </Space>
                      )}
                    </div>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  {isOpenKeywords1 && (
                    <div style={{ marginBottom: 8 }}>
                      <label style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}>
                        Additional Keywords
                        <IconContainer
                          style={{ color: '#00CD5E' }}
                          onClick={() => {
                            setIsOpenKeywords1(false);
                            setAddionalKeywords1([]);
                          }}
                        >
                          <CloseCircleFilled style={{ marginLeft: 4 }} size={2} />
                        </IconContainer>
                      </label>
                      <Input.TextArea
                        placeholder="Enter additional keywords"
                        value={inputText.additionalKeyword1}
                        name="additionalKeyword1"
                        onChange={handleInputChange}
                        autoSize
                        onKeyUp={(e) => handleAddKeyword(e, 'additionalKeyword1')}
                      />
                    </div>
                  )}
                </Col>
                <Col span={8}>
                  {isOpenKeywords2 && (
                    <div style={{ marginBottom: 8 }}>
                      <label style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}>
                        Additional Keywords
                        <IconContainer
                          style={{ color: '#00CD4E' }}
                          onClick={() => {
                            setIsOpenKeywords2(false);
                            setAddionalKeywords2([]);
                          }}
                        >
                          <CloseCircleFilled style={{ marginLeft: 4 }} size={2} />
                        </IconContainer>
                      </label>
                      <Input.TextArea
                        placeholder="Enter additional keywords"
                        value={inputText.additionalKeyword2}
                        name="additionalKeyword2"
                        onChange={handleInputChange}
                        autoSize
                        onKeyUp={(e) => handleAddKeyword(e, 'additionalKeyword2')}
                      />
                    </div>
                  )}
                </Col>
                <Col span={8}>
                  {isOpenKeywords3 && (
                    <div style={{ marginBottom: 8 }}>
                      <label style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}>
                        Additional Keywords
                        <IconContainer
                          style={{ color: '#00CD3E' }}
                          onClick={() => {
                            setIsOpenKeywords3(false);
                            setAddionalKeywords1([]);
                          }}
                        >
                          <CloseCircleFilled style={{ marginLeft: 4 }} size={2} />
                        </IconContainer>
                      </label>
                      <Input.TextArea
                        placeholder="Enter additional keywords"
                        value={inputText.additionalKeyword3}
                        name="additionalKeyword3"
                        onChange={handleInputChange}
                        autoSize
                        onKeyUp={(e) => handleAddKeyword(e, 'additionalKeyword3')}
                      />
                    </div>
                  )}
                </Col>
                <Col span={8}>
                  {isOpenKeywords1 && (
                    <div>
                      {additionalKeywords1.length > 0 && (
                        <Space wrap={true}>
                          {additionalKeywords1.map((keyword, index) => (
                            <StyledTag
                              key={index}
                              closable
                              color="lime"
                              closeIcon={<CloseCircleOutlined style={{ color: 'lime', fontSize: 14 }} />}
                              onClose={(e) => {
                                e.preventDefault();
                                handleRemoveKeyword(index, 'additionalKeyword1');
                              }}
                            >
                              {keyword}
                            </StyledTag>
                          ))}
                        </Space>
                      )}
                    </div>
                  )}
                </Col>
                <Col span={8}>
                  {isOpenKeywords2 && (
                    <div>
                      {additionalKeywords2.length > 0 && (
                        <Space wrap={true}>
                          {additionalKeywords2.map((keyword, index) => (
                            <StyledTag
                              key={index}
                              closable
                              color="lime"
                              closeIcon={<CloseCircleOutlined style={{ color: 'lime', fontSize: 14 }} />}
                              onClose={(e) => {
                                e.preventDefault();
                                handleRemoveKeyword(index, 'additionalKeyword2');
                              }}
                            >
                              {keyword}
                            </StyledTag>
                          ))}
                        </Space>
                      )}
                    </div>
                  )}
                </Col>
                <Col span={8}>
                  {isOpenKeywords3 && (
                    <div>
                      {additionalKeywords3.length > 0 && (
                        <Space wrap={true}>
                          {additionalKeywords3.map((keyword, index) => (
                            <StyledTag
                              key={index}
                              closable
                              color="lime"
                              closeIcon={<CloseCircleOutlined style={{ color: 'lime', fontSize: 14 }} />}
                              onClose={(e) => {
                                e.preventDefault();
                                handleRemoveKeyword(index, 'additionalKeyword3');
                              }}
                            >
                              {keyword}
                            </StyledTag>
                          ))}
                        </Space>
                      )}
                    </div>
                  )}
                </Col>
                <Col span={8}>
                  <Button
                    size="large"
                    type="dashed"
                    style={{
                      width: '100%',
                      height: 180,
                      backgroundColor: '#fafafa',
                    }}
                    onClick={() => setIsOpenKeywords1(true)}
                    disabled={isOpenKeywords1 || additionalKeywords.length === 0}
                    className={isOpenKeywords1 || additionalKeywords.length === 0 ? '' : 'text-black'}
                  >
                    <p>Add Additional Keywords</p>
                  </Button>
                </Col>
                <Col span={8}>
                  <Button
                    size="large"
                    type="dashed"
                    style={{
                      width: '100%',
                      height: 180,
                      backgroundColor: '#fafafa',
                    }}
                    onClick={() => setIsOpenKeywords2(true)}
                    disabled={isOpenKeywords2 || additionalKeywords.length === 0}
                    className={isOpenKeywords2 || additionalKeywords.length === 0 ? '' : 'text-black'}
                  >
                    <p> Add Additional Keywords</p>
                  </Button>
                </Col>
                <Col span={8}>
                  {' '}
                  <Button
                    size="large"
                    type="dashed"
                    style={{
                      width: '100%',
                      height: 180,
                      backgroundColor: '#fafafa',
                    }}
                    onClick={() => setIsOpenKeywords3(true)}
                    disabled={isOpenKeywords3 || additionalKeywords.length === 0}
                    className={isOpenKeywords3 || additionalKeywords.length === 0 ? '' : 'text-black'}
                  >
                    <p>Add Additional Keywords</p>
                  </Button>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: 10 }}>
                <Col span={8} className="gutter-row">
                  <Form.Item name="keywords" label={keywords.length ? 'Main Keywords' : ''}>
                    <Space direction="horizontal">
                      <DisplayFocusKeywords
                        passedType={'main'}
                        checkedValue={focusKeywordsMain}
                        iterateValues={keywords}
                        focus_Keywords={focus_Keywords}
                      />
                    </Space>
                  </Form.Item>
                </Col>
                <Col span={8} className="gutter-row">
                  <Form.Item label={additionalKeywords.length ? 'Main Keywords' : ''} name="main_additional_keywords">
                    <Space direction="horizontal">
                      <DisplayFocusKeywords
                        passedType={'additional'}
                        checkedValue={focusKeywordsAdditional}
                        iterateValues={additionalKeywords}
                        focus_Keywords={focus_Keywords}
                      />
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </BucketCard>
      <div style={{ marginTop: 16 }}>
        <Space size="middle">
          <Button onClick={() => push(`${ROUTES.ADMIN.CLIENTS.CATEGORY}?client=${clientID}`)}>Cancel</Button>
          <Button
            type="primary"
            onClick={() => {
              formAdd.handleSubmit();
              setLoading(true);
            }}
            disabled={formAdd.values.category && formAdd.values.bucket_name && keywords.length ? false : true}
          >
            Save
          </Button>
        </Space>
      </div>
      <CreateCategories
        setLoading={setLoading}
        openModal={categoryModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
        create_category={categoryForm}
        placeholder={'Category Name'}
      />
    </BucketWrapper>
  );
};

export default Create;
